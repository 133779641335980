<template>
  <div class="challenge_container" ref="pageScroll">
    <div>
      <img
        :src="require('@/assets/images/literature/cover.png')"
        class="challenge_cover"
        alt=""
        @load="initScroll"
      />
      <!-- <div class="challenge_status" v-if="false"><span>我已预约参赛</span></div> -->
      <div class="challenge_content">
        <div class="challenge_button_group">
          <div class="challenge_button" @click="goToAdminPage()">
            <img
              :src="require('@/assets/images/literature/admin.png')"
              class="challenge_identity"
              alt=""
            />
            <span>我是管理员</span>
          </div>
          <div class="challenge_button" @click="goToDoctorPage()">
            <img
              :src="require('@/assets/images/literature/doctor.png')"
              class="challenge_identity"
              alt=""
            />
            <span>我是专家</span>
          </div>
        </div>
        <img
          :src="require('@/assets/images/literature/video.png')"
          class="challenge_vote"
          alt=""
          @click="$router.push('/literature/votingList')"
        />
      </div>
    </div>
  </div>
</template>
<script>
import BSScroll from "better-scroll";
import weixin from "@/common/weixin";
export default {
  name: "challenge",
  data() {
    return {
      scroll: null,
    };
  },
  created() {},
  mounted() {
    document.getElementsByTagName("title")[0].innerHTML = "文献讲读专区";
    this.initScroll();
    setTimeout(() => {
      this.initParams();
    }, 1000);
  },
  methods: {
    initParams() {
      let params = {
        title: "",
        desc: "“产科速讯”文献病例分享会",
        url: "",
        logo: "",
      };
      //分享
      weixin.shareMessage(params);
    },
    initScroll() {
      this.$nextTick(() => {
        if (!this.scroll) {
          this.scroll = new BSScroll(this.$refs.pageScroll, {
            click: true,
            mouseWheel: true,
          });
        } else {
          this.scroll.refresh();
        }
      });
    },
    goToAdminPage() {
      // let projectId = this.$config.projectId;
      let projectId = this.$tools.getCompany("literature","projectId");
      window.location.href = `http://kclass.imedunion.com/#/doctorList?projectId=${projectId}`;
    },
    goToDoctorPage() {
      // let projectId = this.$config.projectId;
      // let companyId = this.$config.companyId;
      let companyId = this.$tools.getCompany("literature","companyId");
      let projectId = this.$tools.getCompany("literature","projectId");
      let openid = this.$tools.getCookie("openid");
      window.location.href = `http://kclass.imedunion.com/#/myMeeting?other_openid=${openid}&other_company_id=${companyId}&projectId=${projectId}`;
    },
  },
};
</script>
<style>
.challenge_container {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgb(242, 242, 242);
}
.challenge_cover {
  width: 100%;
  height: auto;
}
.challenge_button_group {
  display: flex;
  justify-content: space-between;
  padding: 12vw 0 4vw;
}
.challenge_button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  width: 36vw;
  height: 20vw;
  font-size: 4.8vw;
  background: #80b25f;
  border-radius: 4vw;
  position: relative;
}
.challenge_identity {
  position: absolute;
  left: -1.33vw;
  top: -8vw;
  height: 12vw;
  width: auto;
  z-index: 1;
}
.challenge_status {
  height: 10vw;
  display: flex;
  justify-content: flex-end;
}
.challenge_status span {
  display: inline-block;
  width: 40vw;
  background: #fff;
  border-top-left-radius: 5vw;
  border-bottom-left-radius: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4.8vw;
  font-weight: 600;
  color: #80b25f;
}
.challenge_content {
  padding: 5.6vw 9.6vw;
}
.challenge_vote {
  width: 100%;
  height: auto;
}
</style>
